import {
    DxToolbar,
    DxItem,
    DxTexts,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    DxLoadPanel,
  } from 'devextreme-vue/data-grid'
  import { Workbook } from 'exceljs'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { convertUTC } from '@/@core/utils/utils'
import { saveAs } from 'file-saver-es'
  import 'devextreme/dist/css/dx.light.css'
  import { booking } from '@/api/booking'
  import { caddyCalendar } from '@/api/caddy-calendar'
  import dayjs from 'dayjs'
  import {
    F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F,S, Z
  } from '@/api/constant.js'
  const dataGridRef = 'tablePackage'
export default {
    name: 'WatingList',
    components: {
        DxToolbar,
        DxItem,
        DxTexts,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        DxLoadPanel,
        ShortKey
      },
    data() {
        return {
            search: {
                KeyWord: null,
                StartTime: this.getUTC('start', 'day'),
                EndTime: this.getUTC('end', 'day')
            },
            currentPage: 1,
            perPage: 50,
            optionPerPage: [10, 50, 100],
            ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, Z],
            allChildComponentClosed: [
                
              ],
              dataGridInstance: null,
            configTable: [
                
                {
                    caption: 'Tee time',
                    field: 'Time',
                    isShow: true,
                    cellTemplate: 'Time-Hour',
                    alignment: 'center',
                    width: '15%',
                    type: 'datetime',
                    editorOptions: {
                        displayFormat: 'HH:mm',
                        useMaskBehavior: false,
                        type: 'datetime',
                    },
                    allowFiltering: false
                },
                {
                    caption: 'Ngày',
                    field: 'OpenDate',
                    isShow: true,
                    cellTemplate: 'Day-Time',
                    alignment: 'center',
                    width: '15%',
                    type: 'datetime',
                    editorOptions: {
                        displayFormat: 'dd/MM/yyyy',
                        useMaskBehavior: false,
                        type: 'date',
                    },
                    allowFiltering: false
                },
                {
                    caption: 'Họ và tên',
                    field: 'FullName',
                    isShow: true,
                    alignment: 'center',
                    width: '15%',
                    allowFiltering: true
                },
                {
                    caption: 'Số điện thoại',
                    field: 'PhoneNumber',
                    isShow: true,
                    alignment: 'center',
                    width: '15%',
                    allowFiltering: true
                },
                {
                    caption: 'Caddy',
                    field: 'CaddyCode',
                    isShow: true,
                    alignment: 'center',
                    width: '15%',
                    allowFiltering: true
                },
                {
                    caption: 'Ghi chú',
                    field: 'Note',
                    isShow: true,
                    alignment: 'center',
                    width: '40%',
                    allowFiltering: true
                }
              ],
            listWatingList: null,
            windowHeight: 0,
            heightTable: 400,
            timeZone: localStorage.getItem('timeZone'),
            isLoading: false
        }
    },
    mounted() {
        this.windowHeight = window.innerHeight
    },
    computed: {
        dataGrid() {
          return this.$refs[dataGridRef].instance
        },
      },
    async created() {
        this.getWatingList_RBKWL1()
    },
    watch: {
        windowHeight(value) {
            if(value > 600 ) {
                this.heightTable = value - 300
            }
        }
    },
    methods: {
        saveGridInstance(e) {
            this.dataGridInstance = e.component
          },
        saveWatingList(data) {
            if(data.changes.length == 0) {
                return
            }
            this.isLoading = true
            data.changes.forEach(async (x, index) => {
                if(x.type === "insert") {
                    if(!x.data.Time || !x.data.FullName) {
                        this.showToast('error', 'Vui lòng nhập đầy đủ thông tin')
                    }  else {
                        const hour = this.dayjs(x.data.Time).tz(this.timeZone).get('hour')
                        const minute = this.dayjs(x.data.Time).tz(this.timeZone).get('minute')
                        const time = x.data.OpenDate 
                        ? this.dayjs(x.data.OpenDate).tz(this.timeZone).set('hours', hour).set( 'minutes',minute).format() 
                        : this.dayjs(x.data.Time).tz(this.timeZone).format()
                        const timeUTC = this.dayjs(time).utc().format()
                        const utctime = this.dayjs(time).utc().format()
                        const body = {
                            BookingWaitingList: {
                                Id: null,
                                Time: utctime,
                                FullName: x.data.FullName ? x.data.FullName : null, 
                                PhoneNumber: x.data.PhoneNumber ? x.data.PhoneNumber : null,
                                CaddyCode: x.data.CaddyCode ? x.data.CaddyCode : null,
                                Note: x.data.Note ? x.data.Note : null
                            }
                        }
                        await this.AddWatingList(body)
                    }
                } else if (x.type === "update") {
                    if(x.data.Time === null || x.data.FullName === null || x.data.OpenDate === null) {
                        this.showToast('error', 'Vui lòng nhập đầy đủ thông tin')
                    } else {
                        if (x.data.Time && !x.data.OpenDate) {
                            const hour = this.dayjs(x.data.Time).tz(this.timeZone).get('hour')
                            const minute = this.dayjs(x.data.Time).tz(this.timeZone).get('minute')
                            const time = this.dayjs(x.key.OpenDate).tz(this.timeZone).set('hours', hour).set( 'minutes',minute).format()
                            var utctime = this.dayjs(time).utc().format()
                        } else if (x.data.OpenDate && !x.data.Time ) {
                            const hour = this.dayjs(x.key.Time).tz(this.timeZone).get('hour')
                            const minute = this.dayjs(x.key.Time).tz(this.timeZone).get('minute')
                            const time = this.dayjs(x.data.OpenDate).tz(this.timeZone).set('hours', hour).set( 'minutes',minute).format()
                            var utctime = this.dayjs(time).utc().format()
                        } else if (x.data.OpenDate && x.data.Time) {
                            const hour = this.dayjs(x.data.Time).tz(this.timeZone).get('hour')
                            const minute = this.dayjs(x.data.Time).tz(this.timeZone).get('minute')
                            const time = this.dayjs(x.data.OpenDate).tz(this.timeZone).set('hours', hour).set( 'minutes',minute).format()
                            var utctime = this.dayjs(time).utc().format()
                        }
                        const body = {
                            BookingWaitingList: {
                                Id: x.data.Id ? x.data.Id : x.key.Id,
                                Time: x.data.Time || x.data.OpenDate ? utctime : x.key.Time,
                                FullName: x.data.FullName ? x.data.FullName : x.key.FullName,
                                PhoneNumber: x.data.PhoneNumber ? x.data.PhoneNumber : x.key.PhoneNumber ,
                                CaddyCode: x.data.CaddyCode ? x.data.CaddyCode : x.key.CaddyCode,
                                Note: x.data.Note ? x.data.Note : x.key.Note 
                            }
                        }
                        await this.AddWatingList(body)
                    }
                } else if (x.type === "remove") {
                    await this.DeleteWatingList({BookingWaitingListId: [x.key.Id]})
                }
                if(index === data.changes.length - 1) {
                    await this.getWatingList_RBKWL1()
                }
            })
        },
        async getWatingList_RBKWL1() {
            this.isLoading = false
            const response = await booking.api_RBKWL1(this.search)
            this.isLoading = false
            if(response.Status === '200') {
                this.listWatingList = response.Data.BookingWaitingList
            } else {
                this.showResToast(response)
            }
        },
        async AddWatingList(body) {
            const response = await booking.api_UBKWL1(body)
            this.showResToast(response)
        }, 
        async DeleteWatingList(body) {
            const response = await booking.api_DBKWL1(body)
            this.showResToast(response)
        },
        exportExcel() {
            const workbook = new Workbook()
            const worksheet = workbook.addWorksheet('Booking')
            const timeZone = localStorage.getItem('timeZone')
            exportDataGrid({
              component: this.dataGrid,
              worksheet,
              autoFilterEnabled: true,
              PrintSelectedRowsOnly: true,
              topLeftCell: { row: 4, column: 1 },
              customizeCell: function(options) {
                const { gridCell, excelCell } = options;
                if(gridCell.rowType === 'data') {
                  if(gridCell.column.dataField == 'OpenDate') {
                    const date = dayjs(gridCell.value).tz(timeZone).format()
                    const utctime = dayjs(date).utc().format()
                    excelCell.value =  convertUTC(utctime,'DD/MM/YYYY')
                  }
                  if(gridCell.column.dataField == 'Time') {
                    const date = dayjs(gridCell.value).tz(timeZone).format()
                    const utctime = dayjs(date).utc().format()
                    excelCell.value =  convertUTC(utctime,'HH:mm')
                  }
            } }
            }).then(cellRange => {
              // header
              const headerRow = worksheet.getRow(2)
              headerRow.height = 30
              worksheet.mergeCells(2, 1, 2, 8)
      
              headerRow.getCell(1).value = `DANH SÁCH CHỜ (từ ${this.convertUTC(this.search.StartTime, 'DD/MM/YYYY')} đến ${this.convertUTC(this.search.EndTime, 'DD/MM/YYYY')})`
              headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
              headerRow.getCell(1).alignment = { horizontal: 'center' }
      
              // footer
              const footerRowIndex = cellRange.to.row + 2
              const footerRow = worksheet.getRow(footerRowIndex)
              worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
              footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
              footerRow.getCell(1).alignment = { horizontal: 'right' }
            }).then(() => {
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WatingList.xlsx')
              })
            })
          },
          onTriggeredEventHandler(payload) {
            if(payload.evt.ctrlKey && payload.key === S) {
                this.$refs.search.$refs.input.focus()
                setTimeout(() => {
                    this.dataGridInstance.saveEditData() 
                },500)
            }
            if(payload.evt.ctrlKey && payload.key === Z) {
              this.dataGridInstance.cancelEditData()
            }
           
          },
    },
}